<template>
  <main>
    <div class="container">
      <div class="pic pic--tangerine"></div>
      <div class="pic pic--tree"></div>
      <div class="container__page">
        <div class="container__content">

          <h1>Добро пожаловать на&nbsp;новогоднюю ярмарку &laquo;Сбудется&raquo;.<br>Здесь ты&nbsp;сможешь найти для себя счастье и&nbsp;новые возможности. Просто выбери подарок в&nbsp;одной из&nbsp;категорий.</h1>

          <hr>

          <p>В&nbsp;честь Нового Года дружная команда МегаФона дарит тебе персональный подарок.<br>
          Номинал твоего подарка <b class="secondary-text">2500&nbsp;рублей</b>.</p>

          <h3>Как получить подарок?</h3>

          <p><b class="secondary-text">Шаг 1:</b> Посмотри, какие подарки входят в&nbsp;каждую категорию</p>
          <p><b class="secondary-text">Шаг 2:</b> Только после того как посмотрел все подарки во&nbsp;всех категориях, выбери ту&nbsp;категорию, подарок которой тебе приглянулся.</p>
          <p><b class="secondary-text">Шаг 3:</b> Нажми кнопку &laquo;Сбудется&raquo;. После нажатия изменить категорию будет уже невозможно.</p>
          <p><b class="secondary-text">Шаг 4:</b> Укажи свой личный <nobr>e-mail</nobr>. Электронный сертификат выбранной категории придет на&nbsp;указанную электронную почту.</p>
          <p><b class="secondary-text">ВАЖНО: тебе придет сертификат на&nbsp;категорию, а&nbsp;сами магазины ты&nbsp;выберешь на&nbsp;следующем шаге из&nbsp;полученного письма. Срок действия твоего сертификата 60 календарных дней.</b></p>
          <p><b class="secondary-text">Шаг 5:</b> Пройди по&nbsp;ссылке из&nbsp;письма с&nbsp;сертификатом, и&nbsp;ты&nbsp;попадешь на&nbsp;витрину магазинов, где и&nbsp;сможешь выбрать финально свой подарок. Номинал необходимо использовать единоразово. Выбрать можешь несколько сертификатов на&nbsp;сумму не&nbsp;более чем 2500&nbsp;рублей.</p>
          <p>Выбранные подарки будут доставлены тебе на&nbsp;указанную электронную почту.</p>

          <p>Молодец, что прочитал инструкцию, а&nbsp;вот тут ты&nbsp;найдешь видео о&nbsp;том, как все сделать правильно и&nbsp;получить заветный подарок:</p>

          <div class="row">
            <div class="col-xl-6 col-lg-8 col-md-10">
              <div class="video video--16-9">
                <video src="https://st.giftery.ru/rewards/megafon/tutorial.mp4" controls></video>
              </div>
            </div>
          </div>

          <hr>

          <h3>Возникли вопросы?</h3>
          <p>Свяжись с нами в рабочие дни <b>с 08:00 до 20:00 (МСК)</b></p>
          <p>Служба заботы о клиентах: <b><a href="tel:88007009090">8&nbsp;800&nbsp;700 90 90</a></b><br>
          Email: <b><a href="mailto:support@giftery.ru">support@giftery.ru</a></b></p>
          <br>
          <div>
            <span class="secondary-text" style="display: inline-block; margin-right: 10px; font-weight: bold;">Ну что? </span>
            <router-link class="button button--large button--accent button--rounded" :to="{ name: 'Catalog' }">Помчали!</router-link>
          </div>
          <br>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import 'base/components/Cabinet'

export default {
  name: 'Cabinet',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--cabinet')
  }
}
</script>

<style lang="css" scoped>
  .buttons {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    width: 80%;
  }

  .buttons a {
    display: flex;
    text-align: center;
    font-size: 20px;
    padding: 1em;
    width: 32.3%;
    margin-bottom: 15px;
  }

  .buttons a span {
    display: block;
    margin: auto;
    line-height: 1.2;
  }

  @media (max-width: 991px) {
    .buttons {
      flex-wrap: wrap;
      width: auto;
    }

    .buttons a {
      width: 32.3%;
    }
  }

  @media (max-width: 575px) {
    .buttons {
      display: block;
    }

    .buttons a {
      width: auto;
    }
  }

  .text-danger {
    color: #c00;
    font-weight: bold;
  }
</style>
